import React, { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import { Fade } from '@mui/material';
import Introduction from './components/Introduction';

import CommercialImage from '../../assets/images/product-pages/commercial.jpg';
import WhatsCovered from './components/WhatsCovered';
import Downloads from './components/Downloads';

// Icons
import HousFireIcon from '../../assets/images/icons/housefire.svg';
import StormIcon from '../../assets/images/icons/storm.svg';
import AccidentIcon from '../../assets/images/icons/accident.svg';
import ElectricIcon from '../../assets/images/icons/electric.svg';
import StorageTankIcon from '../../assets/images/icons/storage-tank.svg';
import RegulationIcon from '../../assets/images/icons/regulation.svg';
import LandslideIcon from '../../assets/images/icons/landslide.svg';
import WalletIcon from '../../assets/images/icons/wallet.svg';
import SecurityGuardIcon from '../../assets/images/icons/security-guard.svg';
import SprinkleIcon from '../../assets/images/icons/sprinkle.svg';
import FireExtinguisherIcon from '../../assets/images/icons/fire-extinguisher.svg';
import LandscapeIcon from '../../assets/images/icons/landscape.svg';
import WaterWavesIcon from '../../assets/images/icons/water-waves.svg';
import GraffitiIcon from '../../assets/images/icons/graffiti.svg';
import ElectricGeneratorIcon from '../../assets/images/icons/electric-generator.svg';

// Documents
import KeyInformation from '../../assets/documents/products-key-information/commercial/Broadform-Building-Key-Information-Document-1-April-2023.pdf';
import CommercialInsurance from '../../assets/documents/products-key-information/commercial/Broadform-Building-Insurance-Policy-April-2023.pdf';
import InsuranceProposal from '../../assets/documents/products-key-information/Insurance-Proposal-Form-November 2024.pdf';

const CommercialBuildingInsuranceRoot = styled('div')(({ theme }) => ({}));

const content = {
    image: CommercialImage,
    title: 'Commercial Building Insurance',
    paragraph_one: (
        <Fragment>
            Anyone intrinsically involved in commercial / residential property management recognises
            the importance of protecting their significant investment. CIA has an almost 20-year
            track record of success in developing products to mitigate the risks incurred by their
            clients across the full spectrum of building insurance.
        </Fragment>
    ),
    paragraph_two: (
        <Fragment>
            The CIA Building Insurance Policy provides cover on a <b>Broadform All Risk basis</b>{' '}
            for commercial, industrial and residential buildings, such as offices, schools, shopping
            centres, hospitals, factories and warehouses.
        </Fragment>
    ),
    btnText: 'Get a Quote',
    btnUrl: '/get-quote#commercial-insurance',
};

const icon_items = [
    {
        title: 'Fire',
        text: 'Cover for loss or damage to buildings caused by fire and includes fires due to arson.',
        icon: HousFireIcon,
    },
    {
        title: 'Natural Disasters',
        text: 'Such a hailstorms, floods, tornados, lightning, earthquakes and tsunamis.',
        icon: StormIcon,
    },
    {
        title: 'Accidental Damage',
        text: 'Unforeseen and unintentional damage such as spilling paint on a carpet or drilling through a pipe in a wall.',
        icon: AccidentIcon,
    },
    {
        title: 'Loss of Rent',
        text: 'Loss of rent or alternative accommodation if your building becomes uninhabitable following damage until the building is again tenanted or until reasonable access to the building has been restored.',
        icon: WalletIcon,
    },
    {
        title: 'Geysers',
        text: 'Geyser and maintenance cover, choose your preferred limit. Includes heatpumps and solar installations.',
        icon: StorageTankIcon,
    },
    {
        title: 'Subsidence and Landslide',
        text: 'Limited cover (excluding dolomite and limestone areas).',
        icon: LandslideIcon,
    },
    {
        title: 'Power Surge',
        text: 'R50,000 included.',
        icon: ElectricIcon,
    },
    {
        title: 'Public Liability',
        text: 'Up to R100 million, including prior acts coverage.',
        icon: RegulationIcon,
    },
    {
        title: 'Security Guards',
        text: 'Appointment of temporary security to safeguard the property following damage.',
        icon: SecurityGuardIcon,
    },
    {
        title: 'Sprinklers',
        text: 'Sprinkler system upgrades required by legislation following damage.',
        icon: SprinkleIcon,
    },
    {
        title: 'Fire Extinguisher',
        text: 'Reasonable cost and expenses to extinguish a fire.',
        icon: FireExtinguisherIcon,
    },
    {
        title: 'Landscaped Gardens',
        text: 'Reinstatement of gardens following fire, impact and explosion.',
        icon: LandscapeIcon,
    },
    {
        title: 'Fallen Trees',
        text: 'Causing damage to the building.',
        icon: StormIcon,
    },
    {
        title: 'Water and Dehumidification',
        text: 'Following water damage.',
        icon: WaterWavesIcon,
    },
    {
        title: 'Theft and Malicious Damage',
        text: 'Full cover for theft and malicious damage following forcible and violent entry into the building.',
        icon: GraffitiIcon,
    },
    {
        title: 'Generator Hire',
        text: 'Up to R15,000 following damage.',
        icon: ElectricGeneratorIcon,
    },
];

const document_items = [
    {
        title: 'Broadform Building (Commercial) Insurance Policy',
        text: 'Document size: 521KB',
        document: CommercialInsurance,
    },
    {
        title: 'Broadform Building (Commercial) Insurance Policy - Key Information Document',
        text: 'Document size: 265KB',
        document: KeyInformation,
    },
    {
        title: 'Insurance Proposal Form',
        text: 'Document size: 235KB',
        document: InsuranceProposal,
    },
];

const CommercialBuildingInsurance = () => {
    return (
        <Fade in={true}>
            <CommercialBuildingInsuranceRoot>
                <Introduction content={content} />

                <WhatsCovered icon_items={icon_items} />

                <Downloads document_items={document_items} />
            </CommercialBuildingInsuranceRoot>
        </Fade>
    );
};

export default CommercialBuildingInsurance;
