import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Button, Container, Divider, Grid, IconButton, Link, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPhone, faBars } from '@fortawesome/free-solid-svg-icons';

import ProductsMenu from './mega-menu-items/ProductsMenu';
import UsefulInfoMenu from './mega-menu-items/UsefulInfoMenu';
import SelfAssistMenu from './mega-menu-items/SelfAssistMenu';

import CiaLogo from '../../../assets/images/logos/cia_logo_nav.svg';
import NavbarMobile from './NavbarMobile';

const NavbarRoot = styled('div')(({ theme }) => ({
    backgroundColor: '#fff',
    position: 'fixed',
    width: '100%',
    height: '81px',
    '&.shadow': {
        boxShadow: '0px 0px 12px 2px rgb(0 0 0 / 8%)',
    },
    zIndex: 2,
}));

const NavbarInner = styled('div')(({ theme }) => ({
    display: 'flex',
}));

const NavbarLogo = styled('img')(({ theme }) => ({
    height: '72px',
    padding: '5px 0',
    cursor: 'pointer',
}));

const DesktopNavbar = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
        display: 'none',
    },
}));

const NavbarLinkContainer = styled('div')(({ theme }) => ({
    padding: '0 16px',
    height: '81px',
    display: 'flex',
    alignItems: 'center',
}));

const NavbarLinkItem = styled('div')(({ theme }) => ({
    color: '#474747',
    fontSize: '16px',
    cursor: 'pointer',
    padding: '8px',
    '&:hover': {
        color: '#D7412E',
        '& .nav-icon': {
            transform: 'rotate(180deg)',
        },
    },
    '&.active': {
        color: '#D7412E',
    },
    '&.open': {
        color: '#D7412E',
        '& .nav-icon': {
            transform: 'rotate(180deg)',
        },
    },
}));

const NavIcon = styled(FontAwesomeIcon)(({ theme }) => ({
    fontSize: '13px',
    transition: '250ms ease',
    // marginLeft: '2px',
}));

const NavbarActionsContainer = styled('div')(({ theme }) => ({
    padding: '0 16px',
    height: '81px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
}));

const MegaMenu = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '81px',
    backgroundColor: 'transparent',
    width: '100%',
    padding: '24px 0 0',
    marginTop: '-24px',
    zIndex: 2,
}));

const MegaMenuInner = styled('div')(({ theme }) => ({
    backgroundColor: '#fff',
    height: '100%',
}));

const Backdrop = styled('div')(({ theme }) => ({
    position: 'fixed',
    width: '100%',
    height: '100%',
    // top: 0,
    left: 0,
    backgroundColor: 'rgba(228, 228, 228, 0.432)',
    backdropFilter: 'blur(0.7px)',
    zIndex: -1,
}));

const MobileNavbar = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'none',
    alignItems: 'center',
    marginRight: '20px',
    [theme.breakpoints.down('md')]: {
        display: 'flex',
    },
}));

const PhoneIconButton = styled(IconButton)(({ theme }) => ({
    marginLeft: 'auto',
    marginRight: '10px',
    height: '40px',
    width: '40px',
    padding: '10px',
    borderRadius: '.3rem',
}));

const PhoneIcon = styled(FontAwesomeIcon)(({ theme }) => ({
    color: '#474747',
    fontSize: '25px',
    transform: 'rotate(270deg)',
}));

const BurgerIconButton = styled(IconButton)(({ theme }) => ({
    // marginLeft: 'auto',
    height: '40px',
    width: '40px',
    padding: '10px',
    borderRadius: '.3rem',
}));

const BurgerIconClose = styled(FontAwesomeIcon)(({ theme }) => ({
    color: '#474747',
    fontSize: '25px',
}));

const BurgerIconOpen = styled(FontAwesomeIcon)(({ theme }) => ({
    color: '#474747',
    fontSize: '25px',
}));

const Navbar = () => {
    const history = useHistory();

    const [navShadow, setNavShadow] = useState(false);
    const [currentLink, setCurrentLink] = useState(null);
    const [megaMenuShow, setMegaMenuShow] = useState({ display: 'none' });
    const [megaMenuContent, setMegaMenuContent] = useState('Products');
    const [mobileOpen, setMobileOpen] = useState(false);

    useEffect(() => {
        setCurrentLink(window.location.pathname);
    }, []);

    useEffect(() => {
        setCurrentLink(window.location.pathname);

        return history.listen((location) => {
            window.scrollTo(0, 0);

            setCurrentLink(location.pathname);
            setMegaMenuShow({ display: 'none' });
        });
    }, [history]);

    useEffect(() => {
        window.onscroll = function () {
            myFunction();
        };

        function myFunction() {
            if (window.pageYOffset !== 0) {
                setNavShadow(true);
                return;
            }

            setNavShadow(false);
        }
    }, []);

    useEffect(() => {
        if (megaMenuShow['display'] === 'none') {
            setMegaMenuContent(null);
        }
    }, [megaMenuShow]);

    return (
        <NavbarRoot className={navShadow && 'shadow'}>
            <Container maxWidth="lg">
                <NavbarInner>
                    <NavbarLogo
                        src={CiaLogo}
                        alt="Commercial and Industrial Acceptances (Pty) Ltd Logo"
                        onClick={() => {
                            mobileOpen && setMobileOpen(false);
                            history.push('/');
                        }}
                    />

                    <DesktopNavbar>
                        <NavbarLinkContainer>
                            <NavbarLinkItem
                                className={currentLink === '/' && 'active'}
                                onClick={() => history.push('/')}
                            >
                                Home
                            </NavbarLinkItem>
                            <NavbarLinkItem
                                className={currentLink === '/about-us' && 'active'}
                                onClick={() => history.push('/about-us')}
                            >
                                About Us
                            </NavbarLinkItem>
                            <NavbarLinkItem
                                className={megaMenuContent === 'Products' && 'open'}
                                onMouseEnter={(e) => {
                                    setMegaMenuContent('Products');
                                    setMegaMenuShow({ display: 'block' });
                                }}
                                onMouseLeave={(e) => {
                                    setMegaMenuShow({ display: 'none' });
                                }}
                            >
                                Products <NavIcon className="nav-icon" icon={faChevronDown} />
                            </NavbarLinkItem>
                            <NavbarLinkItem
                                className={megaMenuContent === 'UsefulInfo' && 'open'}
                                onMouseEnter={(e) => {
                                    setMegaMenuContent('UsefulInfo');
                                    setMegaMenuShow({ display: 'block' });
                                }}
                                onMouseLeave={(e) => {
                                    setMegaMenuShow({ display: 'none' });
                                }}
                            >
                                Useful Info <NavIcon className="nav-icon" icon={faChevronDown} />
                            </NavbarLinkItem>
                            <NavbarLinkItem
                                className={megaMenuContent === 'SelfAssist' && 'open'}
                                onMouseEnter={(e) => {
                                    setMegaMenuContent('SelfAssist');
                                    setMegaMenuShow({ display: 'block' });
                                }}
                                onMouseLeave={(e) => {
                                    setMegaMenuShow({ display: 'none' });
                                }}
                            >
                                Self Assist <NavIcon className="nav-icon" icon={faChevronDown} />
                            </NavbarLinkItem>
                        </NavbarLinkContainer>

                        <NavbarActionsContainer>
                            <NavbarLinkItem
                                className={currentLink === '/contact-us' && 'active'}
                                onClick={() => history.push('/contact-us')}
                            >
                                Contact Us
                            </NavbarLinkItem>

                            <NavbarLinkItem
                                onClick={() => (window.location.href = 'https://app.cia.co.za')}
                            >
                                Broker Login
                            </NavbarLinkItem>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => history.push('/claim')}
                            >
                                Claim
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: '10px' }}
                                onClick={() => history.push('/get-quote')}
                            >
                                Get a Quote
                            </Button>
                        </NavbarActionsContainer>
                    </DesktopNavbar>
                    <MobileNavbar>
                        <PhoneIconButton href="tel:+27861242777">
                            <PhoneIcon icon={faPhone} />
                        </PhoneIconButton>

                        <BurgerIconButton onClick={() => setMobileOpen(!mobileOpen)}>
                            <BurgerIconOpen icon={faBars} />
                        </BurgerIconButton>
                    </MobileNavbar>
                </NavbarInner>
            </Container>
            <MegaMenu
                style={megaMenuShow}
                onMouseEnter={(e) => {
                    setMegaMenuShow({ display: 'block' });
                }}
                onMouseLeave={(e) => {
                    setMegaMenuShow({ display: 'none' });
                }}
            >
                <MegaMenuInner>
                    {megaMenuContent === 'Products' ? (
                        <ProductsMenu />
                    ) : megaMenuContent === 'UsefulInfo' ? (
                        <UsefulInfoMenu />
                    ) : megaMenuContent === 'SelfAssist' ? (
                        <SelfAssistMenu />
                    ) : null}
                </MegaMenuInner>
            </MegaMenu>

            <NavbarMobile
                currentLink={currentLink}
                mobileOpen={mobileOpen}
                handleClick={() => setMobileOpen(false)}
            />

            <Backdrop hidden={megaMenuShow['display'] === 'none'} />
        </NavbarRoot>
    );
};

export default Navbar;
