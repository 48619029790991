import React from 'react';
import { Link, Typography } from '@mui/material';
import FidelityComputerCrimeQuotationProposalForm from '../../../../../assets/documents/products-key-information/fidelity/Fidelity-And-Computer-Crime-Quotation-Proposal-Form.pdf';

const FidelityCover = () => {
    return (
        <div style={{ textAlign: 'center' }}>
            <Typography variant="h5" color="#666666">
                In order to provide you with a detailed quote please download and complete our
                Fidelity & Computer Crime Proposal form{' '}
                <Link href={FidelityComputerCrimeQuotationProposalForm} target="_blank">
                    here
                </Link>
            </Typography>
            <Typography variant="h5" color="#666666" marginTop={2}>
                Once completed please return via email to{' '}
                <Link href="mailto:quotes@cia.co.za">quotes@cia.co.za</Link> and we'll send your
                quote through shortly.
            </Typography>
        </div>
    );
};

export default FidelityCover;
